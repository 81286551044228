import { useState, useEffect, useRef, useCallback } from 'react';
import { useRouter } from 'next/router';
import { LOAD_GTAG_SCRIPTS } from '.';

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

const isBrowser = typeof window !== 'undefined';

function getScrollPosition({ element, useWindow }) {
    if (!isBrowser) return { x: 0, y: 0 };

    const target = element ? element.current : document.body;
    const position = target.getBoundingClientRect();

    return useWindow ? { x: window.scrollX, y: window.scrollY } : { x: position.left, y: position.top };
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
    const position = useRef(getScrollPosition({ useWindow }));

    let throttleTimeout = null;

    const callBack = () => {
        const currPos = getScrollPosition({ element, useWindow });
        effect({ prevPos: position.current, currPos });
        position.current = currPos;
        throttleTimeout = null;
    };

    useEffect(() => {
        const handleScroll = () => {
            if (wait) {
                if (throttleTimeout === null) {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    throttleTimeout = setTimeout(callBack, wait);
                }
            } else {
                callBack();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, deps);
}

export function useInterval(callback, delay, executeNow = false) {
    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        if (executeNow) {
            savedCallback?.current?.();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function tick() {
            savedCallback?.current?.();
        }

        const id = setInterval(tick, delay);

        if (delay === null) {
            clearInterval(id);
        }

        return () => clearInterval(id);
    }, [delay]);
}

export function usePushEffect(id) {
    const { locale, push } = useRouter();

    function callbackEvent(event) {
        const target = event.currentTarget;

        const pathname = target?.pathname.replace(/^\/https/g, 'https');

        if (pathname.startsWith('/')) {
            event.preventDefault();
            push((target?.pathname || '') + (target?.search || ''));
        }

        return false;
    }

    useEffect(() => {
        const buttons = document.querySelectorAll(`#${id} a`);
        buttons.forEach((button) => {
            button.addEventListener('click', callbackEvent);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    useEffect(() => () => {
        const buttons = document.querySelectorAll(`#${id} a`);
        buttons.forEach((button) => {
            button.removeEventListener('click', callbackEvent);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

export function useRedirectToDefaultLocale() {
    const { asPath, locale, replace } = useRouter();

    useEffect(() => {
        const checkLocale = (process.env.NEXT_PUBLIC_LOCALES).split(',').some((l) => asPath.includes(l));

        if (!checkLocale) {
            replace(`/${locale}${asPath}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asPath]);
}

export function useGtagDataLayer({ title, path }) {
    const HAD_DATA_LAYER = typeof window !== 'undefined' && window.dataLayer;

    const prevPath = usePrevious(HAD_DATA_LAYER ? path : Math.random());

    const setPush = useCallback(() => {
        if (title && LOAD_GTAG_SCRIPTS) {
            console.log('🐵', path);
            window.dataLayer?.push({
                event: 'pageview',
                page: { path, title },
            });
        }
    }, [path, title]);

    if (path !== prevPath && HAD_DATA_LAYER) {
        setPush();
    }
}
