import { Cookie } from '@src/utils';
import { get, simplePost } from '@src/utils/methods';

export function getSuggestionsApi(data, limit) {
    const query = { filter: data, limit };
    return get(`${process.env.NEXT_PUBLIC_REST}search`, query);
}

export function getSegmentsApi(data) {
    return get(`${process.env.NEXT_PUBLIC_REST}segment`, data);
}

export function getSessionApi(locale) {
    let data;

    if (Cookie.get('DRUPAL_SESSION_ID')) {
        data = { DRUPAL_SESSION_ID: Cookie.get('DRUPAL_SESSION_ID') };
    }

    return simplePost(`${process.env.NEXT_PUBLIC_API_DRUPAL}/${locale}/api/get-session`, data);
}

export function setLogoutApi(locale) {
    let data;

    if (Cookie.get('DRUPAL_SESSION_ID')) {
        data = { DRUPAL_SESSION_ID: Cookie.get('DRUPAL_SESSION_ID') };
    }

    return simplePost(`${process.env.NEXT_PUBLIC_API_DRUPAL}/${locale}/api/logout`, data);
}
