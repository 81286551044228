import axios from 'axios';
import forEach from 'lodash/forEach';

/**
 * Return Axios of method GET
 * @param {string} url Url of ajax
 * @param {object} data Params of ajax
 * @author Youssef Lahssini
 */
export function get(url, params, ignoreToken = false, cancelToken) {
    const headers = {};
    headers['Content-Type'] = 'application/json';
    headers.Accept = 'application/json';

    if (!ignoreToken) {
        headers.Authorization = `Bearer ${process.env.NEXT_PUBLIC_TOKEN}`;
    }

    return axios({
        url,
        method: 'get',
        params,
        headers,
        cancelToken,
    });
}

/**
 * Return Axios of method POST
 * @param {string} url Url of ajax
 * @param {object} data Params of ajax
 * @author Youssef Lahssini
 */
export function post(url, data) {
    return axios({
        url,
        method: 'post',
        headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

/**
 * Return Axios of method POST, it send data in FormDATA with credentials include
 * @param {string} url Url of ajax
 * @param {object} data Params of ajax
 * @author Youssef Lahssini
 */
export function simplePost(url, data) {
    const headers = [];
    const formData = new FormData();

    forEach(data, (value, key) => {
        formData.append(key, value);
    });

    headers['Content-type'] = 'application/x-www-form-urlencoded';

    return fetch(url, {
        method: 'POST',
        body: formData,
        headers,
        credentials: 'include',
    });
    /* return axios({ url,
        method: 'post',
        data: formData,
        headers,
        withCredentials: true,
    }); */
}

/**
 * Return Axios of method PUT
 * @param {string} url Url of ajax
 * @param {object} data Params of ajax
 * @author Youssef Lahssini
 */
export function put(url, data) {
    return axios({
        url,
        method: 'put',
        headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

export function head(url, params, ignoreToken = false) {
    const headers = {};

    if (!ignoreToken) {
        headers.Authorization = `Bearer ${process.env.NEXT_PUBLIC_TOKEN}`;
    }

    return axios({
        url,
        method: 'head',
        params,
        headers,
    });
}
