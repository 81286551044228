import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useWindowSize, useRedirectToDefaultLocale } from '@src/utils/hooks';
import * as Styles from './main.styled';

const Header = dynamic(() => import('./Header'), { ssr: true });
const Footer = dynamic(() => import('./Footer'), { ssr: true });
const Cookies = dynamic(() => import('./Cookies'), { ssr: false });
const MobileMenu = dynamic(() => import('./MobileMenu'), { ssr: false });

/**
 * Main layout
 * @description This component contains header, footer, Zendesk Chat, button to go up and finally mobile menu components
 * @author Youssef Lahssini
 */
const MainLayout = ({ layout, children, getSession, setMobileMenuStatus }) => {
    const [pos, setPos] = useState(0);
    const [y, setY] = useState(0);
    const { locale, pathname } = useRouter();
    const { height } = useWindowSize();

    useScrollPosition(({ currPos }) => {
        setY(Math.abs(currPos.y));
    });

    useRedirectToDefaultLocale();

    useEffect(() => {
        getSession(locale);
    }, [getSession, locale, pathname]);

    /**
    * This function hide the mobile menu if it displayed by clicking in parent div
    */
    function handleMenu() {
        if (layout?.menuOpened) {
            setMobileMenuStatus(false);
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        }
    }

    /**
     * The role of this function is scroll to top of document by clicking in button with arrow up
     */
    function handleSC(position) {
        setPos(position);
    }

    function handleGotoTop() {
        window.scrollTo(0, 0);
    }

    return (
        <Styles.Main>
            { layout?.menuBackground && <Styles.MenuBackground /> }
            <Styles.Content position={pos} className={layout?.menuOpened ? '__opened' : ''} role="button" onClick={handleMenu}>
                <Header handleSC={handleSC} />
                {children}
                <Footer />

                <Styles.GoToTop
                    type="button"
                    id="layout-flyer-goto"
                    onClick={handleGotoTop}
                    aria-label="Go to top"
                    className={height && y >= (height / 2) ? '__showed' : ''}
                >
                    <span className="i-arrow-up" />
                </Styles.GoToTop>

                <Cookies />

                { /* <ZendeskChat /> */ }
            </Styles.Content>

            <MobileMenu />
        </Styles.Main>
    );
};

export default MainLayout;
