import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setMobileMenuStatus, getSession } from '@src/redux/actions/layoutActions';
import MainLayout from './mainLayout';

const mapStateToProps = ({ layout, landingPage }) => ({ layout, landingPage });

const mapDispatchToProps = (dispatch) => bindActionCreators({ setMobileMenuStatus, getSession }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
