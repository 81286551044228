import styled from 'styled-components';
import { size, em, rgba } from 'polished';

export const Loading = styled.div`
    ${size('100%', '100%')}
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;

    > div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
`;

export const Main = styled.main`
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #fff;
`;

export const Content = styled.div`
    ${size('100%', '100%')}
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        ${size('100%', '100%')}
        background: rgba(0,0,0,0.3);
        content: '';
        opacity: 0;
        transform: translate3d(100%,0,0);
        transition: opacity 0.4s, transform 0s 0.4s;
        transition-timing-function: cubic-bezier(0.7,0,0.3,1);
        z-index: 1000;
    }

    &.__opened {
        &:before {
            opacity: 1;
            transition: opacity 0.8s;
            transform: translate3d(0,0,0);

            @media (min-width: 991px) {
                opacity: 0;
                transition: opacity 0.4s;
            }
        }
    }
`;

export const GoToTop = styled.button`
    border-radius: 50em;
    background-color: var(--primary);
    color: #fff;
    ${size('36px', '36px')}
    display: block;
    transition: all .2s ease-in;
    line-height: 36px;
    cursor: pointer;
    font-size: ${em('24px')};
    position: fixed;
    bottom: 9em;
    right: 1.5em;
    padding: 0;
    z-index: 11;
    opacity: 0;
    filter: brightness(100%);

    &.__showed {
        opacity: 1;
        color: white;
    }

    > span {
        transition: top .2s ease-in;
        position: relative;
        top: 0;
    }

    &:hover {
        filter: brightness(94%);

        > span {
            top: -2px;
        }
    }
`;

export const MenuBackground = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background: ${rgba('black', 0.5)};
    z-index: 1;
`;
