import styled from 'styled-components';
import { Container } from 'reactstrap';
import { CONTAINER } from '../../assets/styles/settings.styled';

const StyledContainer = styled(Container)`
    width: 96%;

    @media (min-width: 1200px) {
        max-width: ${CONTAINER};
    }
`;

export default StyledContainer;
