import isPlainObject from 'lodash/isPlainObject';
import {
    SET_SEARCH_FIELD,
    SET_MOBILE_MENU_STATUS,
    SET_MOBILE_CATEGORY_STATUS,
    INCREMENT_TOTAL_CART,
    GET_SESSION,
    SET_TOP_URL_TYPE,
    SET_LOGOUT,
    SET_NATIVE_DATA,
    SET_MENU_BACKGROUND,
    SET_LOCALE_URLS,
    SET_BREADCRUMBS,
} from '../types/layoutTypes';
import { SET_NATIVE_LANDING } from '../types/landingPageTypes';
import { actionCreator, Cookie } from '../../utils';
import {
    getSessionApi,
    setLogoutApi,
    getSuggestionsApi,
} from '../api/layoutApi';

export function setLocaleUrls(payload) {
    return {
        type: SET_LOCALE_URLS,
        payload,
    };
}

export function setTopUrlType(payload) {
    return {
        type: SET_TOP_URL_TYPE,
        payload,
    };
}

export function setBreadcrumbs(payload) {
    return {
        type: SET_BREADCRUMBS,
        payload,
    };
}

export function setSearchField(search) {
    return {
        type: SET_SEARCH_FIELD,
        search,
    };
}

export function setMobileMenuStatus(status) {
    return {
        type: SET_MOBILE_MENU_STATUS,
        status,
    };
}

export function setMobileCategoryStatus(category) {
    return {
        type: SET_MOBILE_CATEGORY_STATUS,
        category,
    };
}

export function setMenuBackground(menuBg) {
    return {
        type: SET_MENU_BACKGROUND,
        menuBg,
    };
}

export function getSessionReq() {
    return {
        type: actionCreator(GET_SESSION, 'req'),
    };
}

export function getSessionRes(data) {
    return {
        type: actionCreator(GET_SESSION, 'res'),
        data,
    };
}

export function getSession(locale) {
    return async (dispatch) => {
        dispatch(getSessionReq());

        try {
            const sessionApi = await getSessionApi(locale);
            const sessionApiJson = await sessionApi.json();

            dispatch(getSessionRes({
                cart: sessionApiJson.cart && sessionApiJson.cart !== null && sessionApiJson.cart.orderItems ? sessionApiJson.cart.orderItems.length : 0,
                profile: isPlainObject(sessionApiJson.user) ? sessionApiJson.user : null,
                cookie: Cookie.get('DRUPAL_SESSION_ID'),
            }));
        } catch (e) {
            console.log(e);
            dispatch(getSessionRes({
                cart: 0,
                profile: null,
                cookie: '',
            }));
        }
    };
}

export function getSuggestionsData(data, limit) {
    return () => new Promise((resolve, reject) => {
        const dataStringified = JSON.stringify(data);

        getSuggestionsApi(dataStringified, limit)
            .then((response) => response.data)
            .then((suggestion) => {
                resolve(suggestion);
            })
            .catch(() => reject());
    });
}

export function incrementTotalCart() {
    return {
        type: INCREMENT_TOTAL_CART,
    };
}

export function setLogoutReq() {
    return {
        type: actionCreator(SET_LOGOUT, 'req'),
    };
}

export function setLogoutRes(data) {
    return {
        type: actionCreator(SET_LOGOUT, 'res'),
        data,
    };
}

export function setLogoutErr() {
    return {
        type: actionCreator(SET_LOGOUT, 'err'),
    };
}

export function setLogout(locale) {
    return async (dispatch) => {
        dispatch(setLogoutReq());

        try {
            await setLogoutApi(locale);
            return dispatch(setLogoutRes({
                cookie: Cookie.get('DRUPAL_SESSION_ID'),
            }));
        } catch (error) {
            return dispatch(setLogoutErr());
        }
    };
}

export function setNativeData(data) {
    return {
        type: SET_NATIVE_DATA,
        data,
    };
}

export function setNativeLanding(data) {
    return {
        type: SET_NATIVE_LANDING,
        data,
    };
}
